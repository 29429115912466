import React from "react";
export default class BulletPointsOrGrid extends React.Component {
  getSingleColumn = (arrayForThisColumn, indexOfThisColumn) => {
    const { bulletTextStyle, containerStyle, backgroundColor, dataObject } =
      this.props;
    const textColor =
      this.props.textColor || backgroundColor === "black" ? "white" : "black";
    const bulletWidth = 8;

    return (
      <div
        className="flexColumn"
        style={{ ...containerStyle, margin: "20px 0px" }}
        key={indexOfThisColumn}
      >
        {arrayForThisColumn.map((bulletPoint, i) => (
          <div
            id="bulletPoint row"
            className="flexRow"
            style={{
              ...bulletTextStyle,
              flex: 1,
              justifyContent: "flex-start",
              color: textColor,
              textAlign: "left",
              marginRight: 20,
              marginBottom: 10,
            }}
            key={i}
          >
            {bulletTextStyle && (
              <div
                style={{
                  backgroundColor:
                    dataObject && dataObject.highlightColor
                      ? dataObject.highlightColor
                      : "rgb(255,105,0)",
                  borderRadius: bulletWidth / 2,
                  height: bulletWidth,
                  width: bulletWidth,
                  marginRight: bulletWidth,
                  marginLeft: 20,
                  marginTop: 5,
                  flexShrink: 0,
                }}
              />
            )}

            {bulletPoint}
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { array, columns, columnsPhone, containerStyle, isPhone } =
      this.props;
    const columnCount = isPhone && columnsPhone ? columnsPhone : columns;
    if (columnCount && columnCount > 1) {
      const hashOfColumnArrays = {};
      let currentColumnIndex = 0;
      for (let index = 0; index < array.length; index++) {
        const columnArray = hashOfColumnArrays[currentColumnIndex] || [];
        columnArray.push(array[index]);
        hashOfColumnArrays[currentColumnIndex] = columnArray;
        if (currentColumnIndex < columnCount - 1) {
          currentColumnIndex++;
        } else {
          currentColumnIndex = 0;
        }
      }
      return (
        <div className="flexRow flexCenter" style={{ ...containerStyle }}>
          {Object.values(hashOfColumnArrays).map((e, i) =>
            this.getSingleColumn(e, i)
          )}
        </div>
      );
    }

    return this.getSingleColumn(array);
  }
}
